import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NumberInput } from '@mantine/core';
import { memo, useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import IntradayTrendChart from '~/modules/trendChart';
import { Display } from '~/pages/heineken_template/_col/col_WatchSymbolToggleButton';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { TrafficLight } from '~/pages/winner98/modules/TrafficLight';
import { VolumeFilter } from '~/pages/winner98/_private/component/VolumeFilter';
import { Winner98_DefaultListSymbol } from '~/pages/winner98/_private/component/winner98_DefaultListSymbol';
import { Winner98_MasterSymbol } from '~/pages/winner98/_private/component/winner98_MasterSymbol';
import { Winner98_MultipleWatchListSymbol } from '~/pages/winner98/_private/component/winner98_MultipleWatchListSymbol';
import { PageModeButtonSelector } from '~/pages/winner98/_private/component/winner98_PageModeSelector';
import { Winner98_PopularSymbol } from '~/pages/winner98/_private/component/winner98_PopularSymbol';
import { Styleds } from '~/pages/winner98/_private/styleds';
import { winner98_agentStore } from '~/pages/winner98/_private/winner98_agentStore';
import { DisplayButton, Winner98StrategyButton1, } from '~/pages/winner98/_private/winner98_SidebarPane1';
import { LinkGroup } from '~/pages/winner98/_private/winner98_Topbar';
const watchListGroup = (state) => {
    if (state === 1)
        return 'winner98_stock_group_1';
    if (state === 2)
        return 'winner98_stock_group_2';
    if (state === 3)
        return 'winner98_stock_group_3';
};
export const Winner98_fullVersion_sidebarPane1 = memo(function Winner98_fullVersion_sidebarPane1() {
    const charting = useSnapshot(store.charting);
    const { isPc } = useMedia();
    const state = useSnapshot(winner98_agentStore);
    const displayTrendChart = state.displayTrendChart;
    return (<Styleds.Sidebar>
        {!isPc && <LinkGroup />}
        {isPc && <Winner98StrategyButton1 />}
        <Styleds.TitleQuoteContent>
          <TitleSymbolQuote.Default symbol={charting.symbol}/>
          <DisplayButton />
        </Styleds.TitleQuoteContent>
        {state.displayTrendChart === true && (<Styleds.TrendChartContent>
            <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-15} priceTicksSize={11}/>
          </Styleds.TrendChartContent>)}
        <Styleds.WatchSymbolContent>
          <Display groupName={watchListGroup(state.userStockGroup)} maxSize={20}/>
        </Styleds.WatchSymbolContent>
        <Styleds.TrafficContent>
          <TrafficLight titleName='大盤能量號誌燈'/>
        </Styleds.TrafficContent>
        <PageModeButtonSelector />
        <Styleds.PanlContent displayTrendChart={displayTrendChart}>
          <winner98_agentStore.tabs1.WithContent for='主力選股'>
            <div css={listContentCss}>
              <Winner98_DefaultListSymbol />
            </div>
            <div css={css `
                width: 100%;
                height: 140px;
              `}>
              <TradeButton />
            </div>
            <div css={vilterContentCss}>
              <VolumeFilter />
            </div>
          </winner98_agentStore.tabs1.WithContent>
          <winner98_agentStore.tabs1.WithContent for='熱門股'>
            <Winner98_PopularSymbol />
          </winner98_agentStore.tabs1.WithContent>
          <winner98_agentStore.tabs1.WithContent for='大師選股'>
            <Winner98_MasterSymbol />
          </winner98_agentStore.tabs1.WithContent>
          <winner98_agentStore.tabs1.WithContent for='自選股'>
            <Winner98_MultipleWatchListSymbol />
          </winner98_agentStore.tabs1.WithContent>
        </Styleds.PanlContent>
      </Styleds.Sidebar>);
});
const listContentCss = css `
  ${fill_vertical_cross_center};
  height: calc(100% - 180px);
  width: 100%;
`;
const vilterContentCss = css `
  heigh: 40px;
  width: 100%;
`;
//--demo下單機--
export const TradeButton = memo(function TradeButton() {
    const charting = useSnapshot(store.charting);
    const currentSymbol = charting.symbol;
    useEffect(() => {
        signalrStore2.addQuote(currentSymbol);
        return () => {
            signalrStore2.removeQuote(currentSymbol);
        };
    }, [JSON.stringify(currentSymbol)]);
    const value = useSnapshot(signalrStore2.values.quote)[currentSymbol];
    const close = value?.close ?? 0;
    const [tradeQty, setTradeQty] = useState(1);
    const [tradeTradePrice, setTradePrice] = useState(close);
    const [position, setPosition] = useState('N');
    const handlers = useRef();
    useEffect(() => {
        setTradePrice(close);
    }, [close, currentSymbol]);
    const kgi_url = 'https://webotstest.kgieworld.com.tw/WFThirdSignOn.aspx?Data=StkOdr%2C' +
        currentSymbol +
        '%2C' +
        position +
        '%2C' +
        tradeQty +
        '%2C' +
        tradeTradePrice +
        '%2C%7C';
    const capital_url = 'https://capitalh5.capital.com.tw/Login.aspx?source=z7&stockID=' +
        currentSymbol +
        '&OrderStatus=' +
        position;
    return (<TradeBoard.container position={position}>
      <TradeBoard.Item>
        <TradeBoard.orderButton onClick={() => setPosition('B')} active={position === 'B'} fill='#ea0000'>
          買進
        </TradeBoard.orderButton>
        &nbsp;
        <TradeBoard.orderButton onClick={() => setPosition('S')} active={position === 'S'} fill='#00ba00'>
          賣出
        </TradeBoard.orderButton>
      </TradeBoard.Item>
      <TradeBoard.Item>
        <div>價格</div>
        <TradeBoard.numberInput>
          <NumberInput css={TradeBoard.mantineNumberInput} decimalSeparator='.' precision={2} defaultValue={tradeTradePrice} hideControls // => 先隱藏,需要各商品最小tick才能做調整
     value={tradeTradePrice} onChange={val => setTradePrice(val || 0)}/>
        </TradeBoard.numberInput>
        &nbsp;&nbsp;&nbsp;
        <div>張數</div>
        <TradeBoard.numberInput>
          <NumberInput css={TradeBoard.mantineNumberInput} defaultValue={1} handlersRef={handlers} 
    //hideControls
    step={1} min={1} max={499} value={tradeQty} onChange={val => setTradeQty(val || 0)}/>
        </TradeBoard.numberInput>
      </TradeBoard.Item>
      <TradeBoard.Item css={css `
          background-color: #555555aa;
          width: 90%;
          border-radius: 8px;
        `}>
        <div>預估金額</div>
        <div>
          $
          {tradeTradePrice !== 0 ? (tradeTradePrice * 1000 * tradeQty).toLocaleString() : '– – – –'}
        </div>
        <TradeBoard.orderButton active={true} fill='#aaaaaa' onClick={() => {
            position !== 'N' &&
                window.open(capital_url, 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          群益下單
        </TradeBoard.orderButton>
        <TradeBoard.orderButton active={true} fill='#aaaaaa' onClick={() => {
            position !== 'N' &&
                window.open(kgi_url, 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          凱基下單
        </TradeBoard.orderButton>
      </TradeBoard.Item>
      {/* {currentSymbol},{position},{tradeTradePrice},{tradeQty} */}
    </TradeBoard.container>);
});
export const TradeBoard = {
    container: styled.div `
    ${fill_vertical_all_center};
    ${jc.spaceAround};
    width: 100%;
    height: 100%;
    background-color: ${options => options.position === 'N' ? '#424346' : options.position === 'B' ? '#82535d' : '#52735d'};
    border-radius: 5px;
    padding: 4px 0px;
    gpa: 4px;
  `,
    Item: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    gap: 8px;
  `,
    numberInput: styled.div `
    ${fill_horizontal_all_center};
    width: 80px;
  `,
    orderButton: styled.div `
    ${fill_horizontal_all_center};
    width: 132px;
    background-color: ${options => options.fill + 66};
    color: #999999;
    border-radius: 5px;
    cursor: pointer;
    height: 32px;
    &:hover {
      background-color: ${options => options.fill + 'aa'};
    }
    ${options => {
        const longState = options.active === true &&
            css `
          color: #ffffff;
          background-color: ${options.fill};
        `;
        const shortState = options.fill && css ``;
        return css([longState, shortState]);
    }}
  `,
    mantineNumberInput: css `
    &.mantine-InputWrapper-root {
      .mantine-NumberInput-rightSection {
        color: #252525;
      }
      .mantine-NumberInput-input {
        background-color: #efefef;
        color: #252525;
        height: 32px;
        min-height: 32px;
      }
      .mantine-NumberInput-controlUp {
        color: #252525;
        &:hover {
          background-color: #dfdfdf;
        }
      }
      .mantine-NumberInput-controlDown {
        color: #252525;
        &:hover {
          background-color: #dfdfdf;
        }
      }
    }
  `,
};
